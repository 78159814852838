import {
  getEdgeNodes,
  graphql,
  newId,
  useHistory,
  useLazyLoadQuery,
  useMutation,
} from "@workflows/runtime-web";
import {
  Button,
  Callout,
  Card,
  CardBody,
  CardHeader,
  ChoiceList,
  ChoiceListProps,
  Column,
  ColumnBody,
  ColumnFooter,
  FormControl,
  FormInput,
  Label,
  LinkButton,
  NonIdealState,
  Section,
  SectionBody,
  Select,
  SelectProps,
  Text,
  useForm,
  useFormWatch,
  useViewerTenant,
} from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useRouter } from "~/core/useRouter";
import { routes } from "~/de.smartconex.vertragsgenerator/routes";
import { booleanTransform } from "~/de.smartconex.vertragsgenerator/wizard/utils";
import { Statuses } from "~/core/statuses";
import { VertragsgeneratorRoles } from "~/core/roles";
import { AppDokgeneratorWorkflowsEmploymentContractHessenmetallv1TypeStepMutation } from "~/__graphql__/AppDokgeneratorWorkflowsEmploymentContractHessenmetallv1TypeStepMutation.graphql";
import { AppDokgeneratorWorkflowsEmploymentContractHessenmetallv1TypeStepQuery } from "~/__graphql__/AppDokgeneratorWorkflowsEmploymentContractHessenmetallv1TypeStepQuery.graphql";
import { Header } from "docx";
import { Heading } from "~/documents/elements/Heading";

export interface AppDokgeneratorWorkflowsEmploymentContractv1TypeStepProps {
  collectionId: string;
}

interface TemplateProps {
  changelog: string;
  version: string;
  workflow: string;
}

export function AppDokgeneratorWorkflowsEmploymentContractv1TypeStep({
  collectionId,
}: AppDokgeneratorWorkflowsEmploymentContractv1TypeStepProps): JSX.Element {
  const { tenantId, userId } = useViewerTenant(true);
  const { t } = useTranslation("de.smartconex.vertragsgenerator");
  const history = useHistory();
  const form = useForm("AppDokgeneratorWorkflowsEmploymentContractv1");
  const values = useFormWatch("AppDokgeneratorWorkflowsEmploymentContractv1", [
    "collectiveAgreement.active",
    "collectiveAgreement.at",
    "template",
  ]);

  const { baseUrl } = useRouter();
  const data =
    useLazyLoadQuery<AppDokgeneratorWorkflowsEmploymentContractHessenmetallv1TypeStepQuery>(
      $AppDokgeneratorWorkflowsEmploymentContractHessenmetallv1TypeStepQuery,
      { collectionId }
    );

  const [error, setError] = React.useState("");
  const [createDocument, isPending] =
    useMutation<AppDokgeneratorWorkflowsEmploymentContractHessenmetallv1TypeStepMutation>(
      $AppDokgeneratorWorkflowsEmploymentContractHessenmetallv1TypeStepMutation
    );

  const handleSubmit = async () => {
    const { isValid } = await form.validate();

    if (!isValid) {
      return;
    }

    const { template, title, description, ...values } = form.getValues();

    // This can only happen when the user directly accesses this URL
    // TODO: This should be handled by a model-driven form.
    if (!title) {
      setError(
        t("AppDokgeneratorWorkflowsEmploymentContractv1.errors.unspecified")
      );
      return;
    }

    const input = {
      assignees: [
        {
          assigneeId: userId,
          roleIdentifier: VertragsgeneratorRoles.Assignee,
        },
      ],
      statusIdentifier: Statuses.Open,
      clientMutationId: newId(),
      templateId: template.id,
      tenantId,
      title,
      description,
      defaultValues: Object.entries(values)
        .map(([key, value]) => ({
          identifier: key,
          value: JSON.stringify(value),
        }))
        .filter((val) => !!val.value),
    };

    createDocument({
      variables: { input },
      onError(error) {
        console.error(error);
        setError(
          t("AppDokgeneratorWorkflowsEmploymentContractv1.errors.unspecified")
        );
      },
      onCompleted(data) {
        if (data.createDocument?.errors) {
          console.error(data.createDocument?.errors);
          setError(
            t("AppDokgeneratorWorkflowsEmploymentContractv1.errors.unspecified")
          );
        } else if (data.createDocument && data.createDocument.document) {
          const documentId = data.createDocument.document.id;
          history.replace(
            routes["wizard.index"].build({ documentId, slug: "start" })
          );
        } else {
          console.error(data.createDocument?.errors);
          setError(
            t("AppDokgeneratorWorkflowsEmploymentContractv1.errors.unspecified")
          );
        }
      },
    });
  };

  const collection = data.node;
  const templates = getEdgeNodes(
    collection && collection.items ? collection.items : null
  ).filter((tmpl) => tmpl.__typename === "DocumentTemplate");

  if (templates.length === 0) {
    return (
      <Column focus="primary" width={0.5} background="default">
        <ColumnBody>
          <NonIdealState
            title="Keine Vertragstypen verfügbar"
            description="Ihr Benutzerkonto hat keine Vorlagen zugeordnet. Bitte kontaktieren Sie den Support um das Problem zu lösen."
          />
        </ColumnBody>
      </Column>
    );
  }

  const identifierNTC =
    "de.hessenmetall.documents.employmentContract.standard.ntc";
  const identifierWOTC =
    "de.hessenmetall.documents.employmentContract.standard.wotc";
  const identifierTC =
    "de.hessenmetall.documents.employmentContract.standard.tc";

  if (
    values["collectiveAgreement.active"] === true &&
    values["collectiveAgreement.at"] === false
  ) {
    const template = templates.find(
      (item) => (item.props as TemplateProps).changelog === identifierTC
    );
    form.setValues({
      template,
    });
  } else if (
    values["collectiveAgreement.active"] === true &&
    values["collectiveAgreement.at"] === true
  ) {
    const template = templates.find(
      (item) => (item.props as TemplateProps).changelog === identifierWOTC
    );
    form.setValues({
      template,
    });
  } else if (values["collectiveAgreement.active"] === false) {
    const template = templates.find(
      (item) => (item.props as TemplateProps).changelog === identifierNTC
    );
    form.setValues({
      template,
    });
  }

  let legalNote;
  switch (values["template"]?.props.changelog) {
    case identifierTC:
      legalNote =
        "Dieses Arbeitsvertragsmuster wurde für Arbeitgeber erstellt, die der Tarifbindung in der hessischen Metall- und Elektroindustrie unterliegen (sog. Tarifgebundene Unternehmen). Es enthält daher einen Verweis auf die einschlägigen Tarifwerke und zahlreiche Regelungen, denen die tariflichen Regelungen der hessischen Metall- und Elektroindustrie zugrunde liegen. Unterliegt Ihr Unternehmen einem anderen Flächen- oder Haustarifvertrag ersetzen Sie die betreffenden Regelungen bitte durch die Regelungen des für Sie maßgeblichen Tarifvertrages oder einen Hinweis auf diese tarifvertraglichen Regelungen. Die Juristinnen und Juristen Ihres Verbandes unterstützen Sie gerne dabei.";
      break;
    case identifierWOTC:
      legalNote =
        "Dieses Arbeitsvertragsmuster wurde für Arbeitgeber erstellt, die der Tarifbindung in der hessischen Metall- und Elektroindustrie unterliegen (sog. Tarifgebundene Unternehmen). Es wurde für Arbeitnehmer erstellt, deren Aufgabengebiet höhere Anforderungen stellt als die höchste tarifliche Entgeltgruppe es verlangt und deren Vertragsbedingungen im Ganzen gesehen die tariflichen Bedingungen überschreiten (sog. Außertarifliche Angestellte).";
      break;
    case identifierNTC:
      legalNote =
        "Dieses Arbeitsvertragsmuster wurde für Arbeitgeber erstellt, die nicht der Tarifbindung unterliegen (sog. OT-Unternehmen, d.h. Unternehmen ohne Tarifbindung). Es enthält daher zahlreiche Regelungen zu Punkten, die regelmäßig in Tarifverträgen geregelt sind. Unterliegt Ihr Unternehmen einem anderen Flächen- oder Haustarifvertrag ersetzen Sie die betreffenden Regelungen bitte durch die Regelungen des für Sie maßgeblichen Tarifvertrages oder einen Hinweis auf diese tarifvertraglichen Regelungen. Die Juristinnen und Juristen Ihres Verbandes unterstützen Sie gerne dabei.";
      break;
  }

  return (
    <>
      <Column focus="primary" width={0.5} background="default">
        <ColumnBody>
          <Section>
            <SectionBody>
              <Text variant="headingLg">Konfiguration</Text>
              {error && <Callout intent="critical">{error}</Callout>}
            </SectionBody>
          </Section>
          <Section>
            <SectionBody>
              <FormControl
                htmlFor="collectiveAgreement.active"
                name="collectiveAgreement.active"
                label="Ist das Unternehmen an die Tarifverträge der Metall- und Elektroindustrie in Hessen gebunden?"
                description=""
              >
                <FormInput<ChoiceListProps>
                  component={ChoiceList}
                  id="collectiveAgreement.active"
                  name="collectiveAgreement.active"
                  type="radio"
                  options={[
                    {
                      label: "Ja",
                      value: "true",
                    },
                    {
                      label: "Nein",
                      value: "false",
                    },
                  ]}
                  transform={booleanTransform()}
                  required="Bitte füllen Sie dieses Feld aus."
                />
              </FormControl>

              {values["collectiveAgreement.active"] === true && (
                <FormControl
                  htmlFor="collectiveAgreement.at"
                  name="collectiveAgreement.at"
                  label="Handelt es sich bei dem Arbeitnehmer um einen so genannten Außertariflichen Angestellten (AT-Angesteller), d.h. stellt das Aufgabengebiet des Arbeitnehmers höhere Anforderungen als die höchste tarifliche Entgeltgruppe es verlangt und überschreiten seine Vertragsbedingungen im Ganzen gesehen die tariflichen Bedingungen?"
                  description=""
                >
                  <FormInput<ChoiceListProps>
                    component={ChoiceList}
                    id="collectiveAgreement.at"
                    name="collectiveAgreement.at"
                    type="radio"
                    options={[
                      {
                        label: "Ja",
                        value: "true",
                      },
                      {
                        label: "Nein",
                        value: "false",
                      },
                    ]}
                    transform={booleanTransform()}
                    required="Bitte füllen Sie dieses Feld aus."
                  />
                </FormControl>
              )}
              {values["template"] && (
                <div>
                  <Label>Vertragstyp:</Label>
                  <p>{values["template"]?.title}</p>
                </div>
              )}
            </SectionBody>
          </Section>
        </ColumnBody>
        <ColumnFooter
          start={
            <LinkButton to={baseUrl}>
              {t("AppDokgeneratorWorkflowsEmploymentContractv1.actions.back")}
            </LinkButton>
          }
          end={
            <Button
              intent="primary"
              onClick={handleSubmit}
              isLoading={isPending}
            >
              {t("AppDokgeneratorWorkflowsEmploymentContractv1.actions.create")}
            </Button>
          }
        />
      </Column>
      <Column style={{ border: "none" }}>
        <ColumnBody inset scrollable={false}>
          {values["template"] && (
            <Card
              style={{ maxWidth: "30rem" }}
              backgroundColor={"var(--color-background-warning-muted)"}
            >
              <CardHeader title="Rechtlicher Hinweis" />
              <CardBody>{legalNote}</CardBody>
            </Card>
          )}
        </ColumnBody>
      </Column>
    </>
  );
}

const $AppDokgeneratorWorkflowsEmploymentContractHessenmetallv1TypeStepQuery = graphql`
  query AppDokgeneratorWorkflowsEmploymentContractHessenmetallv1TypeStepQuery(
    $collectionId: ID!
  ) {
    node(id: $collectionId) {
      ... on Collection {
        name
        items(first: 100) {
          edges {
            cursor
            node {
              id
              ... on DocumentTemplate {
                __typename
                id
                title
                description
                color
                props
              }
            }
          }
        }
      }
    }
  }
`;

const $AppDokgeneratorWorkflowsEmploymentContractHessenmetallv1TypeStepMutation = graphql`
  mutation AppDokgeneratorWorkflowsEmploymentContractHessenmetallv1TypeStepMutation(
    $input: CreateDocumentInput!
  ) {
    createDocument(input: $input) {
      errors {
        code
        path
        message
      }
      document {
        id
      }
    }
  }
`;
