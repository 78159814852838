import { useHistory } from "@workflows/runtime-web";
import {
  Card,
  CardBody,
  CardHeader,
  Column,
  ColumnBody,
  ColumnFooter,
  FormControl,
  FormInput,
  LinkButton,
  Text,
  TextArea,
  TextAreaProps,
  TextField,
  TextFieldProps,
  useForm,
} from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useRouter } from "~/core/useRouter";

export function AppDokgeneratorWorkflowsEmploymentContractv1DetailsStep(): JSX.Element {
  const history = useHistory();
  const { baseUrl } = useRouter();
  const { t } = useTranslation("de.smartconex.vertragsgenerator");
  const form = useForm("AppDokgeneratorWorkflowsEmploymentContractv1");

  return (
    <>
      <Column focus="primary" width={0.5}>
        <ColumnBody inset>
          <Text variant="headingLg">Details</Text>
          <FormControl
            name="title"
            label={t(
              "AppDokgeneratorWorkflowsEmploymentContractv1.inputs.title"
            )}
            helper={t(
              "AppDokgeneratorWorkflowsEmploymentContractv1.helpers.title"
            )}
          >
            <FormInput<TextFieldProps>
              autoFocus
              component={TextField}
              name="title"
              required="Bitte füllen Sie dieses Feld aus."
              max={[250, "Bitte geben Sie max. 250 Zeichen ein"]}
            />
          </FormControl>
          <FormControl
            name="description"
            label={t(
              "AppDokgeneratorWorkflowsEmploymentContractv1.inputs.description"
            )}
            helper={t(
              "AppDokgeneratorWorkflowsEmploymentContractv1.helpers.description"
            )}
          >
            <FormInput<TextAreaProps>
              component={TextArea}
              name="description"
              max={[500, "Bitte geben Sie max. 500 Zeichen ein"]}
            />
          </FormControl>
        </ColumnBody>
        <ColumnFooter
          start={
            <LinkButton to="/">
              {t("AppDokgeneratorWorkflowsEmploymentContractv1.actions.cancel")}
            </LinkButton>
          }
          end={
            <LinkButton
              intent="primary"
              to={`${baseUrl}/details`}
              onClick={async (e) => {
                // TODO: We should add something like `onBeforeRedirect` to the `Link`
                // because it is a common pattern.
                e.preventDefault();
                const { isValid } = await form.validate();
                if (isValid) {
                  history.push(`${baseUrl}/type`);
                }
              }}
            >
              {t("AppDokgeneratorWorkflowsEmploymentContractv1.actions.next")}
            </LinkButton>
          }
        />
      </Column>
      <Column style={{ border: "none" }}>
        <ColumnBody inset scrollable={false}>
          <Card
            style={{ maxWidth: "30rem" }}
            backgroundColor={"var(--color-background-warning-muted)"}
          >
            <CardHeader title="Bitte beachten Sie:" />
            <CardBody>
              Die Vertragsformulare, die mit dem Generator erstellt werden
              können, wurden mit größter Sorgfalt erstellt. Es sind Vorschläge,
              wie die typische Interessenlage zwischen den Parteien sachgerecht
              ausgeglichen werden können, die aber nicht jede Fallkonstellation
              abbilden können. Für den Fall ihrer Verwendung und Veränderung im
              Einzelfall erheben sie daher keinen Anspruch auf Vollständigkeit
              und Richtigkeit. Vor einer Übernahme ist die tatsächliche
              Geeignetheit im Einzelfall zu prüfen. Hierzu stehen Ihnen die
              Juristinnen und Juristen Ihres Verbandes gerne zur Verfügung, die
              auch ergänzende Regelungen für besondere Fallkonstellationen (z.B.
              Außendiensttätigkeit, Arbeitszeitkonto im betriebsratslosen
              Betrieb) zur Verfügung stellen können.
            </CardBody>
          </Card>
        </ColumnBody>
      </Column>
      <Column style={{ border: "none" }}>
        <ColumnBody inset scrollable={false}>
          <div style={{ maxWidth: "30rem" }}></div>
        </ColumnBody>
      </Column>
    </>
  );
}
